import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Spinner, Modal, ModalBody, Button } from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"
import FileDownload from "js-file-download"

const DataTableTirage = props => {
  const [loading, setLoading] = useState(false)
  // Data Table
  const [orders, setOrders] = useState([])
  const [modalVal, setModalVal] = useState(false)
  const [idVal, setIdVal] = useState("")

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: "Enseignant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "fichier",
      text: "Fichier",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "nb_copie",
      text: "Nombre de copie",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Demande Tirage",
      isDummyField: true,
      text: "Demande Tirage",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          title="Telecharger"
          style={{ cursor: "pointer" }}
          onClick={() => download(row)}
        >
          <i
            style={{
              paddingLeft: "5px",
              fontSize: "20px",
              color: "cornflowerblue",
            }}
            className="fas fa-file-download"
          ></i>
        </div>
      ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: "Action",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat == 0 ? (
          <i
            style={{ color: "#143F6B", cursor: "pointer" }}
            onClick={() => toggleVal(row.id)}
            className="fas fa-cogs"
          ></i>
        ) : row.etat == 2 ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => toggleVal(row.id)}
            className="fas fa-times"
          ></i>
        ) : (
          <i
            style={{ color: "#2ca67a", cursor: "pointer" }}
            className="fas fa-check"
          ></i>
        ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("scolarite/tirage/list").then(res => {
      setOrders(res.data.Tirage)
      setLoading(true)
    })
  }, [])

  const valide = async () => {
    const res = await API.post("scolarite/tirage/notify_success", {
      id: idVal,
    }).then(res => {
      setModalVal(!modalVal)
      const resD = API.get("scolarite/tirage/list").then(resD => {
        setOrders(resD.data.Tirage)
        setLoading(true)
      })
    })
  }
  const refuse = async () => {
    const res = await API.post("scolarite/tirage/notify_declined", {
      id: idVal,
    }).then(res => {
      setModalVal(!modalVal)
      const resD = API.get("scolarite/tirage/list").then(resD => {
        setOrders(resD.data.Tirage)
        setLoading(true)
      })
    })
  }

  const toggleVal = id => {
    setModalVal(!modalVal)
    setIdVal(id)
  }

  const download = async row => {
    const res = await API.post(
      "tirage/download",
      {
        id: row.id,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${row.fichier}`)
    })
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En Cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modalVal} toggle={toggleVal} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={toggleVal}
        >
          {props.t("Validation demande de tirage")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-success" onClick={valide}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  {props.t("Valider")}
                </Button>
              </div>
              <div>
                <Button className="btn btn-danger" onClick={refuse}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-ban"
                  ></i>
                  {props.t("Refuser")}
                </Button>
              </div>
              <div>
                <Button onClick={toggleVal} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableTirage))
DataTableTirage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
