import React, {Fragment, useEffect, useState} from "react"
import {Button, CardTitle, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select";
import API from "../../api";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const PermuterGroupe = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [niveau, setNiveau] = useState([]);
    const [selectNiveau, setSelectNiveau] = useState("")
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
    ])
    const [cycle, setCycle] = useState([
        {value: 1, label: "Licence"},
        {value: 2, label: "Mastère"}
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [specialite, setSpecialite] = useState([]);
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [matiere, setMatiere] = useState([]);
    const [selectMatiere, setSelectMatiere] = useState("");
    const [selectCycle, setSelectCycle] = useState("");
    const [type, setType] = useState([]);
    const [selectType, setSelectType] = useState("");
    const [enseignant, setEnseignant] = useState([]);
    const [selectEnseignant, setSelectEnseignant] = useState("");
    const [enseignant1, setEnseignant1] = useState([]);
    const [selectEnseignant1, setSelectEnseignant1] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [groupe1, setGroupe1] = useState([]);
    const [selectGroupe1, setSelectGroupe1] = useState("");
    const [modalDetail, setModalDetail] = useState(false)
    const [title, setTitle] = useState("")
    const [msg, setMsg] = useState("")
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        const res = await API.get("niveau/select").then(res => {
            setNiveau(res.data.Niveau)
        })

    }, [])
    const getCycle = async (e) => {
        setSelectCycle(e)
        setSelectSemestre("")
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectType("")
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
    }
    const getSemestre = async (e) => {
        setSelectSemestre(e)
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectType("")
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
    }
    const getSpecialite = async (e) => {
        setSelectNiveau(e)
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectType("")
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
        const res = await API.post("group/get_list_spec_by_cycle", {
            niveau_id: e.value,
            semestre_id: [selectSemestre],
            cycle_id:selectCycle.value
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getMatiere = async (e) => {
        setSelectSpecialite(e)
        setSelectMatiere("")
        setSelectType("")
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
        const res = await API.post("matiere/get_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value

        }).then(res => {
            setMatiere(res.data.Matiere)
        })
    }
    const getMatiere1 = async (e) => {
        setSelectMatiere(e)
        setSelectType("")
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
        const res = await API.post("matiere/get_detail", {
            matiere_id: e.value,
        }).then(res => {
            setType(res.data.Matiere)
        })

    }
    const getEnseignant = async (e) => {
        setSelectType(e)
        setSelectEnseignant("")
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
        const res = await API.post("enseignant/get_only_with_group", {
            semestre_id: selectSemestre.value,
            matiere_id: selectMatiere.value,
            type: e.value,
            annee_id
        }).then(res => {
            setEnseignant(res.data.ens)
            setEnseignant1(res.data.ens)
        })

    }
    const getGroupe = async (e) => {
        setSelectEnseignant(e)
        setSelectEnseignant1("")
        setSelectGroupe("")
        setSelectGroupe1("")
        const res = await API.post("group/get_by_ens", {
            type: selectType.value,
            enseignant_id: e.value,
            matiere_id: selectMatiere.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setGroupe(res.data.groups)
        })

    }
    const getGroupe1 = async (e) => {
        setSelectEnseignant1(e)
        setSelectGroupe1("")
        const res = await API.post("group/get_by_ens", {
            type: selectType.value,
            enseignant_id: e.value,
            matiere_id: selectMatiere.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setGroupe1(res.data.groups)
        })

    }
    const toggleDetail = async (title,msg) => {
        setModalDetail(!modalDetail)
        if(modalDetail !== true){
            setTitle(title)
            setMsg(msg)
        }
    }
    const save = async () => {
        setDisbutReg(false)
        if(selectCycle === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Cycle est obligatoire .");
        }else if(selectSemestre === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Semestre est obligatoire .");
        }else if(selectNiveau === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Niveau est obligatoire .");
        }else if(selectSpecialite === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Spécialité est obligatoire .");
        }else if(selectMatiere === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Matière est obligatoire .");
        }else if(selectType === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Type matière est obligatoire .");
        }else if(selectEnseignant === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Enseignant est obligatoire .");
        }else if(selectEnseignant1 === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Enseignant est obligatoire .");
        }else if(selectGroupe === ""){
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Groupe est obligatoire .");
        }else if(selectGroupe1 !== ""){
            setDisbutReg(false)
            API.post("emploi/permuter_seances", {
                group_1_id: selectGroupe.value,
                ens_1_id: selectEnseignant.value,
                group_2_id: selectGroupe1.value,
                ens_2_id:selectEnseignant1.value,
                matiere_id:selectMatiere.value,
                type:selectType.value,
                user_id,
                annee_id,
                semestre_id:selectSemestre.value
            }).then(res => {
                if(res.data.errNum === 301 || res.data.errNum ==="301"){
                    setDisbutReg(true)
                    toggleDetail("Groupe non disponible", "⛔ "+res.data.msg);
                }else{
                    props.setSection(1)
                }

            })
        }else{
            setDisbutReg(true)
            await toggleDetail("Champs obligatoire.", "⛔ Groupe est obligatoire .");
        }

    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="6">
                            <fieldset style={{border: '1px solid #ccc', padding: '10px'}}>
                                <legend style={{color: "#556ee6"}}>Choisir le groupe qui sera permuter</legend>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Cycle : </Label>
                                            <Select
                                                options={cycle}
                                                isSearchable={true}
                                                value={selectCycle}
                                                onChange={e => getCycle(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre : </Label>
                                            <Select
                                                options={semestre}
                                                isSearchable={true}
                                                value={selectSemestre}
                                                onChange={e => getSemestre(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau : </Label>
                                            <Select
                                                options={niveau}
                                                isSearchable={true}
                                                value={selectNiveau}
                                                onChange={e => getSpecialite(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                            <Select
                                                options={specialite}
                                                isSearchable={true}
                                                value={selectSpecialite}
                                                onChange={e => getMatiere(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Matière :"} </Label>
                                            <Select
                                                options={matiere}
                                                isSearchable={true}
                                                value={selectMatiere}
                                                onChange={e => getMatiere1(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Type Matière :"} </Label>
                                            <Select
                                                options={type}
                                                isSearchable={true}
                                                value={selectType}
                                                onChange={e => getEnseignant(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Enseignant :"} </Label>
                                            <Select
                                                options={enseignant}
                                                isSearchable={true}
                                                value={selectEnseignant}
                                                onChange={e => getGroupe(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Groupe :"} </Label>
                                            <Select
                                                options={groupe}
                                                isSearchable={true}
                                                value={selectGroupe}
                                                onChange={e => setSelectGroupe(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                        <Col lg="6">
                            <fieldset style={{border: '1px solid #ccc', padding: '10px'}}>
                                <legend style={{color: "#556ee6"}}>Choisir le groupe à permuter</legend>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Cycle : </Label>
                                            <Select
                                                options={cycle}
                                                isSearchable={true}
                                                value={selectCycle}
                                                isDisabled={true}
                                                onChange={e => getCycle(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre : </Label>
                                            <Select
                                                options={semestre}
                                                isSearchable={true}
                                                value={selectSemestre}
                                                isDisabled={true}
                                                onChange={e => getSemestre(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau : </Label>
                                            <Select
                                                options={niveau}
                                                isSearchable={true}
                                                value={selectNiveau}
                                                isDisabled={true}
                                                onChange={e => getSpecialite(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                            <Select
                                                options={specialite}
                                                isSearchable={true}
                                                value={selectSpecialite}
                                                isDisabled={true}
                                                onChange={e => getMatiere(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Matière :"} </Label>
                                            <Select
                                                options={matiere}
                                                isSearchable={true}
                                                value={selectMatiere}
                                                isDisabled={true}
                                                onChange={e => getMatiere1(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Type Matière :"} </Label>
                                            <Select
                                                options={type}
                                                isSearchable={true}
                                                value={selectType}
                                                isDisabled={true}
                                                onChange={e => getEnseignant(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Enseignant :"} </Label>
                                            <Select
                                                options={enseignant1}
                                                isSearchable={true}
                                                value={selectEnseignant1}
                                                onChange={e => getGroupe1(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Groupe :"} </Label>
                                            <Select
                                                options={groupe1}
                                                isSearchable={true}
                                                value={selectGroupe1}
                                                onChange={e => setSelectGroupe1(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                </Form>
                <div style={{display: "flex", justifyContent: "center"}}>
                    {" "}
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={props.back}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                            className="text-center mt-4"
                        >
                            <button type="button" className="btn btn-primary " disabled={!disbutReg} onClick={save}>
                                CONFIRMER
                            </button>
                        </div>
                    </Col>
                </div>

            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
            <Modal isOpen={modalDetail} toggle={toggleDetail} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px"
                    }}
                    toggle={toggleDetail}
                >
                    {title}
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>{msg}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                                marginTop:"17px"
                            }}
                        >

                            <div>
                                <Button
                                    onClick={toggleDetail}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Fermer
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default PermuterGroupe
PermuterGroupe.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
}
