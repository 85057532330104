import React, { useState ,useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/userImage.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [userNameScolarite, setUserNameScolarite] = useState("");

  useEffect(() => {
    const userName = JSON.parse(localStorage.getItem("UserName"));
    if (userName) {
      setUserNameScolarite(userName);
    }
  }, []);
  const clearStorage = () => {
    localStorage.clear()
    window.location.reload(false);
    props.history.push("/login")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button" style={{color:"white"}}
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span style={{color:"white"}}
            className="d-none d-xl-inline-block ms-2 me-1"
          >
            {userNameScolarite}
          </span>
          <i

            className="mdi mdi-chevron-down d-none d-xl-inline-block"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Mon compte")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span onClick={clearStorage}>{props.t("Déconnexion")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
ProfileMenu.propTypes = {
  history: PropTypes.object,
}
