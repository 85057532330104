import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Grid } from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import API from "../../api"

const ForgetPassword = props => {
  const [email, setEmail] = useState("")
  const [display, setDisplay] = useState(0)

  const reset = async () => {
    // setLoading(false)
    const res = await API.post("generate_token", { email: email })
    if (res.data.errNum == 403) {
      toast.error("E-mail n'existe pas", {
        containerId: "A",
      })
    } else if (res.data.errNum == 200) {
      setDisplay(1)
    }
  }

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${cresusFond})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        {display === 0 ? (
          <div style={{ paddingTop: "7%" }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#556ee6",
                              textAlign: "center",
                            }}
                          >
                            Espace Scolarité
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <div className="p-2">
                        <AvForm className="form-horizontal">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              required
                              errorMessage="* E-mail obligatoire"
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </AvForm>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={reset}
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Reset
                        </button>
                      </div>
                      <div className="mt-1 text-center">
                        <p>
                          © {new Date().getFullYear()} Cresus. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger" /> by{" "}
                          <a href="https://cresus.pro/">cresus.pro</a>
                        </p>
                      </div>
                    </CardBody>

                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        ) : (
          <div style={{ padding: "19.2% 0%" }}>
            <Container>
              <div className="alert alert-success" role="alert">
                <p className="mb-0">Cher ,</p>
                <p className="mb-0">
                  Nous avons envoyé un lien à votre e-mail pour réinitialiser
                  votre mot de passe.
                </p>
                <p className="mb-0">
                  Veuillez vérifier votre boîte de réception.
                </p>
                <p className="mb-0">Bonne journée.</p>
              </div>
            </Container>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(ForgetPassword)

ForgetPassword.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
