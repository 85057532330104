import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import warning from "../../assets/images/téléchargement.png"
import $ from "jquery"

const ListEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [modal, setModal] = useState(false)
  const [etat, setEtat] = useState(0)
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee_id = anneeScolaire.Annee.id
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "name",
      text: "Nom & Prénom",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Compostage",
      isDummyField: true,
      text: "Compostage",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>
          <label>{row.compostage}</label>
          <input
            type="text"
            id={row.etudiant_id}
            onChange={e => changeCompostage(e.target.value, row)}
            onBlur={e => checkCompostage(e.target.value, row)}
            defaultValue={row.compostage1}
            style={{ textAlign: "center", marginLeft: "10px" }}
          />
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await API.post("examen/final/list_etudiant", {
      examen_id: props.state_element.id,
      salle: props.listSalle.salle,
    }).then(res => {
      setList(res.data.ListEtudiant)
      setLoading(true)
    })
  }, [props.listSalle.salle])

  const changeCompostage = (value, element) => {
    setList(
      list.map((el) =>
        el.etudiant_id === element.etudiant_id
          ? { ...el, compostage1: value }
          : el
      )
    );
  };
  const checkCompostage = (value, element) => {
    console.log("Value to validate:", value); // Debug
    const regex = /^[A-Za-z]{1}\d{5}$/;

    // Validation regex
    if (!regex.test(value)) {
      console.log("Regex validation failed");
      $("#" + element.etudiant_id).addClass("border_erreur");
      setModal(true);
      setEtat(2);
      return;
    }

    // Validation des doublons
    const compostages = list.map(el => el.compostage1);
    console.log("Compostages in list:", compostages);

    const isDuplicate = compostages.filter(item => item === value).length > 1;
    console.log("Is duplicate:", isDuplicate);

    if (isDuplicate) {
      console.log("Duplicate value found");
      $("#" + element.etudiant_id).addClass("border_erreur");
      setModal(true);
      setEtat(1);
    } else {
      console.log("Value is unique");
      $("#" + element.etudiant_id).removeClass("border_erreur");
      setEtat(0);
    }
  };

  // const save = async () => {
  //   var etat = true
  //   for (let i = 0; i < list.length; i++) {
  //     if (list[i].compostage1.trim().length != 3) {
  //       var etat = false
  //     }
  //   }
  //   if (etat) {
  //     const res = await API.post("examen/final/add_compostage", {
  //       list: list,
  //       examen_id: props.state_element.id,
  //     }).then(res => {
  //       if (res.data.status == 500) {
  //         toast.error(res.data.msg, {
  //           containerId: "A",
  //         })
  //       } else {
  //         toast.success("Compostage ont ajouté aves succées ", {
  //           containerId: "A",
  //         })
  //       }
  //     })
  //   } else {
  //     toast.error(
  //       "⛔ Il y a un ou des champs vides ou compostage supérieure à 3 chiffre !",
  //       {
  //         containerId: "A",
  //       }
  //     )
  //   }
  // }
  const save = async () => {
    var etat = true;
    const regex = /^[A-Z]\d{5}$/;

    for (let i = 0; i < list.length; i++) {
      const compostage1 = list[i].compostage1;
      if (!compostage1 || !regex.test(compostage1.trim())) {
        etat = false;
        break;
      }
    }
    if (etat) {
      const res = await API.post("examen/final/add_compostage", {
        list: list,
        examen_id: props.state_element.id,
      }).then(res => {
        if (res.data.status === 500) {
          toast.error(res.data.msg, {
            containerId: "A",
          });
        } else {
          toast.success("Compostage ajouté avec succès", {
            containerId: "A",
          });
        }
      });
    } else {
      toast.error(
        "⛔ Il y a un ou des champs qui ne respectent pas le format attendu : une lettre majuscule suivie de 5 chiffres.",
        {
          containerId: "A",
        }
      );
    }
  };


  const close = () => {
    setModal(false)
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row className="mt-4">
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={list}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <a
                          href={
                            "https://scolarite.backcresus-institut.ovh/api/impression_list_vierge_emargement/" +
                            props.state_element.id +
                            "/" +
                            props.listSalle.salle+"/"+annee_id
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="button"
                            color="success"
                            className="btn  mb-2 me-2"
                          >
                            <i className="fas fa-file-export me-1" />
                            Export
                          </Button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() =>
                      props.history.push({
                        pathname: "/ListMatieres",
                        state: {
                          element: props.element,
                          niveau_id: props.niveau_id,
                        },
                      })
                    }
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} centered={true}>
        <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
          Faite Attention
        </ModalHeader>
        {etat == 1 ? (
          <ModalBody>
            <div className="d-flex justify-content-center">
              <img
                src={warning}
                alt=""
                style={{ width: 100, textAlign: "center" }}
              />
            </div>
            <p className="mt-4" style={{ textAlign: "center" }}>
              <strong> Compostage doit être unique </strong>
            </p>
          </ModalBody>
        ) : (
          <ModalBody>
            <div className="d-flex justify-content-center">
              <img
                src={warning}
                alt=""
                style={{ width: 100, textAlign: "center" }}
              />
            </div>
            <p className="mt-4" style={{ textAlign: "center" }}>
              <strong> Compostage doit être un seule caractère et 5 chiffres </strong>
            </p>
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(ListEtudiant)
ListEtudiant.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  listSalle: PropTypes.object,
  element: PropTypes.object,
  niveau_id: PropTypes.object,
  state_element: PropTypes.object,
  t: PropTypes.any,
}
