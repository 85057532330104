import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card,CardBody, Container, Nav,NavItem, NavLink} from "reactstrap"
import API from "../../api"
import classnames from "classnames"
import ListEtudiant from "./ListEtudiant"
import axios from "axios";
const ListSalle = props => {
  const state_element = props.location.state.state_element
  const element = props.location.state.element
  const niveau_id = props.location.state.niveau_id
  const [section, setSection] = useState()
  const [listSalle, setListSalle] = useState([])
  useEffect(async () => {
    const res = await API.post("examen/final/list_salle", {
      examen_id: state_element.id,
    }).then(res => {
      setListSalle(res.data.list)
    })
  }, [])
  const renderBlock = section => {
    for (let i = 0; i < listSalle.length; i++) {
      if (section === i) {
        return (
          <ListEtudiant
            listSalle={listSalle[i]}
            element={element}
            niveau_id={niveau_id}
            state_element={state_element}
          />
        )
      }
    }
  }
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
  const uploadPDF = async () => {
    try {
      if (!Array.isArray(listSalle)) {
        console.error("Erreur : listSalle n'est pas un tableau.");
        return;
      }
      const salleJson = JSON.stringify(listSalle);
      const response = await axios.post("https://scolarite.backcresus-institut.ovh/api/impression_list_vierge_emargement_total", {
        salle: listSalle,
        annee_id: annee,
        examen_id: state_element.id
      }, {
        responseType: 'blob'
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Fiche d\'émargement et de notes.pdf');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        console.log("Téléchargement du PDF réussi !");
      } else {
        console.error("Erreur lors du téléchargement du PDF :", response.statusText);
      }
    } catch (error) {
      console.error("Erreur :", error);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="text-sm-end">
                <div className="text-center mt-4">
                  <button
                      className="btn mb-2 me-2"
                      onClick={uploadPDF}
                      style={{ borderColor: "#7d7b7b", width: "10%" }}
                  >
                    <i
                        style={{ color: "#a21c1c", cursor: "pointer", fontSize: "30px", margin: "0px 6px 0px 0px" }}
                        className="fas fa-file-pdf"
                    ></i>
                    <p>{"EXPORT"}</p>
                  </button>

                </div>
              </div>
              <Nav className="icon-tab nav-justified mt-3 mb-3">
                {listSalle.map((element, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === element.section,
                      })}
                      onClick={() => {
                        setSection(element.section)
                      }}
                    >
                      <span className="d-none d-sm-block">{element.salle}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListSalle
ListSalle.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
