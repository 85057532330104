import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const EditEtudiant = props => {
  const [id, setId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [telPere, setTelPere] = useState("")
  const [gender, setGender] = useState(1)
  const [type, setType] = useState(1)
  const [numero, setNumero] = useState("")
  const [date, setDate] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [pays, setPays] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState("")
  const [mention, setMention] = useState("")
  const [moyenne, setMoyenne] = useState("")
  const [session, setSession] = useState("")
  const [paysObtention, setPaysObtention] = useState("")
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [selectDiplome, setSelectDiplome] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const options = useMemo(() => countryList().getData(), [])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    const resC = await API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      setNom(res.data.etudiant.firstName)
      setPrenom(res.data.etudiant.lastName)
      setGender(res.data.etudiant.gender)
      setType(res.data.etudiant.type)
      setNumero(res.data.etudiant.code)
      setSelectNationality(res.data.etudiant.nationality)
      setEmail(res.data.etudiant.email)
      setDate(new Date(res.data.etudiant.birthdate))
      setLieuNaissance(res.data.etudiant.birthplace)
      setSelectGroupe(res.data.etudiant.group)
      setPrenomPere(res.data.etudiant.fatherName)
      setTelPere(res.data.etudiant.fatherPhone)
      setEtatCivil(res.data.etudiant.civilState)
      setRue(res.data.etudiant.addressStreet)
      setCodePostal(res.data.etudiant.addressZipCode)
      setPays(res.data.etudiant.addressCountry)
      setGouvernorat(res.data.etudiant.addressState)
      setSection(res.data.etudiant.bacSection)
      setMention(res.data.etudiant.bacMention)
      setMoyenne(res.data.etudiant.bacMedium)
      setSession(res.data.etudiant.bacSession)
      setAnneeBac(res.data.etudiant.bacYear)
      setPaysObtention(res.data.etudiant.bacCountry)
      setSelectDiplome(res.data.etudiant.diplome)
      setSelectNiveau(res.data.etudiant.niveau)
      setSelectCycle(res.data.etudiant.cycle)
      setSelectSpecialite(res.data.etudiant.specialite)
      setTel(res.data.etudiant.telephone)
      setId(res.data.etudiant.id)
      setLoading(true)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    if (cycle_id == 1) {
      const res = await API.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await API.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }

  const getGroupe = async event => {
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,annee_id
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const editEtudiant = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (date != "") {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (selectCycle == "") {
      toast.error("⛔ Veuillez choisir un cycle", {
        containerId: "A",
      })
    } else if (selectDiplome == "") {
      toast.error("⛔ Veuillez choisir un diplome", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Veuillez choisir un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      toast.error("⛔ Veuillez choisir un groupe", {
        containerId: "A",
      })
    } else if (date == "") {
      toast.error("⛔ Veuillez choisir une date de naissance", {
        containerId: "A",
      })
    } else if (nom != "" && prenom != "" && numero != "" && tel != "") {
      const res = await API.post("etudiant/update", {
        id: id,
        firstName: nom,
        lastName: prenom,
        gender: gender,
        type: type,
        code: numero,
        nationality: selectNationality.label,
        email: email,
        birthdate: convertDate,
        birthplace: lieuNaissance,
        telephone: tel,
        group_id: selectGroupe.value,
        fatherName: prenomPere,
        fatherPhone: telPere,
        civilState: etatCivil,
        addressStreet: rue,
        addressZipCode: codePostal,
        addressCountry: pays,
        addressState: gouvernorat,
        bacSection: section,
        bacMention: mention,
        bacSession: session,
        bacMedium: moyenne,
        bacYear: anneeBac,
        bacCountry: paysObtention,
        specialite_id:selectSpecialite.value,
        niveau_id:selectNiveau.value,
        cycle_id:selectCycle.value,
        annee_id
      })
        .then(res => {
          props.history.push("/Etudiant")
          if (pathFile != "") {
            const formData = new FormData()
            formData.append("document", file)
            formData.append("annee", "2023")
            formData.append("entite", "etudiant")
            formData.append("type", "photo")
            formData.append("id", numero)
            const resA = API.post("file/import", formData).then(resA => {})
          }
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔  Remplir les champs obligatoires", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <AvForm>
                    <Form>
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4"
                      >
                        Informations générales :
                      </CardTitle>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Nom :
                            </Label>
                            <AvField
                              name="nom"
                              placeholder="Entrer nom"
                              type="text"
                              errorMessage="* nom obligatoire"
                              className="form-control"
                              value={nom}
                              validate={{ required: { value: true } }}
                              onChange={e => setNom(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Prénom :
                            </Label>
                            <AvField
                              name="prenom"
                              placeholder="Entrer prénom"
                              type="text"
                              errorMessage=" * prenom obligatoire"
                              className="form-control"
                              value={prenom}
                              validate={{ required: { value: true } }}
                              onChange={e => setPrenom(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Prénom du père :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              placeholder="Entrer nom du père"
                              type="text"
                              value={prenomPere}
                              onChange={e => setPrenomPere(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Téléphone de père :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer Téléphone de père"
                              type="number"
                              value={telPere}
                              onChange={e => setTelPere(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label className="d-block mb-3">Sexe :</Label>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                className="form-check-input"
                                checked={gender === 1}
                                onChange={e => setGender(1)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                              >
                                Masculin
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                className="form-check-input"
                                checked={gender === 2}
                                onChange={e => setGender(2)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline2"
                              >
                                Feminin
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Nationalité :
                            </Label>
                            <Select
                              options={options}
                              isSearchable={true}
                              value={selectNationality}
                              onChange={e => setSelectNationality(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label className="d-block mb-3">Type :</Label>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline3"
                                name="customRadioInline3"
                                className="form-check-input"
                                checked={type === 1}
                                onChange={e => setType(1)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline3"
                              >
                                CIN
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline4"
                                name="customRadioInline3"
                                className="form-check-input"
                                checked={type === 2}
                                onChange={e => setType(2)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline4"
                              >
                                Passeport
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Identifiant :
                            </Label>
                            <AvField
                              name="numero"
                              placeholder="Entrer numéro"
                              type="text"
                              errorMessage="Min 8 caractère"
                              className="form-control"
                              value={numero}
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                              onChange={e => setNumero(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Date de Naissance :{" "}
                            </Label>
                            <Col md={12} className="pr-0">
                              <DatePicker
                                className="form-control ddate"
                                selected={date}
                                onChange={e => setDate(e)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Lieu de naissance :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer lieu de naissance"
                              type="text"
                              value={lieuNaissance}
                              onChange={e => setLieuNaissance(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label className="d-block mb-3">Etat Civil :</Label>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline5"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 1}
                                onChange={e => setEtatCivil(1)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline5"
                              >
                                Célibataire
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline6"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 2}
                                onChange={e => setEtatCivil(2)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline6"
                              >
                                Marié(e)
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                id="customRadioInline7"
                                name="customRadioInline5"
                                className="form-check-input"
                                checked={etatCivil === 3}
                                onChange={e => setEtatCivil(3)}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline7"
                              >
                                Divorcé(e)
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Photo :
                            </Label>
                            <Input
                              onChange={e => addFile(e)}
                              className="form-control"
                              type="file"
                              id="formFile"
                            />
                          </div>
                        </Col>
                      </Row>
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Adresse :
                      </CardTitle>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Rue :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer rue"
                              type="text"
                              value={rue}
                              onChange={e => setRue(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Code postal :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer code postal"
                              type="number"
                              value={codePostal}
                              onChange={e => setCodePostal(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Pays :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer pays"
                              type="text"
                              value={pays}
                              onChange={e => setPays(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Gouvernorat :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer gouvernorat"
                              type="text"
                              value={gouvernorat}
                              onChange={e => setGouvernorat(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Téléphone :{" "}
                            </Label>
                            <AvField
                              name="tel"
                              placeholder="Entrer téléphone"
                              type="text"
                              errorMessage="* Téléphone obligatoire"
                              className="form-control"
                              value={tel}
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                              onChange={e => setTel(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="E-Mail"
                              placeholder="Entrer un email valide"
                              type="email"
                              value={email}
                              errorMessage="Email invalide"
                              onChange={e => setEmail(e.target.value)}
                              validate={{
                                required: { value: true },
                                email: { value: true },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Baccalauréat :
                      </CardTitle>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Année du bac :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer année du bac"
                              type="text"
                              value={anneeBac}
                              onChange={e => setAnneeBac(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Section :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer section"
                              type="text"
                              value={section}
                              onChange={e => setSection(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Moyenne :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer moyenne"
                              type="number"
                              value={moyenne}
                              onChange={e => setMoyenne(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Mention :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer mention"
                              type="text"
                              value={mention}
                              onChange={e => setMention(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Session :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer session"
                              type="text"
                              value={session}
                              onChange={e => setSession(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {`Pays d'obtention`} :{" "}
                            </Label>
                            <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer pays d'obtention"
                              type="text"
                              value={paysObtention}
                              onChange={e => setPaysObtention(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Diplôme :
                      </CardTitle>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Cycle :{" "}
                            </Label>
                            <Select
                              options={cycle}
                              isSearchable={true}
                              value={selectCycle}
                              onChange={e => getNiveau(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Niveau :{" "}
                            </Label>
                            <Select
                              options={niveau}
                              isSearchable={true}
                              value={selectNiveau}
                              onChange={e => getSpec(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Spécialité :
                            </Label>
                            <Select
                              options={specialite}
                              isSearchable={true}
                              value={selectSpecialite}
                              onChange={e => getGroupe(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Groupe :{" "}
                            </Label>
                            <Select
                              options={groupe}
                              isSearchable={true}
                              value={selectGroupe}
                              onChange={e => setSelectGroupe(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() =>
                              props.history.push("/DetailEtudiant?id=" + id)
                            }
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={editEtudiant}
                          >
                            CONFIRMER
                          </button>
                        </div>
                      </Col>
                    </div>
                  </AvForm>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditEtudiant
EditEtudiant.propTypes = {
  history: PropTypes.object,
}
